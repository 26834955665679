<template>
  <div id="app">
    <Banner/>
    <Navbar />
    <Calculator />
    <Info />
    <Allies />
    <Footer />
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Calculator from "./components/Calculator.vue";
import Info from "./components/Info.vue";
import Allies from "./components/Allies.vue";
import Footer from "./components/Footer.vue";
import Banner from './components/Banner.vue'

export default {
  name: "app",
  components: {
    Banner,
    Navbar,
    Calculator,
    Info,
    Allies,
    Footer,
  },
};

</script>

<style>
html,
body {
  overflow-x: hidden;
}
@font-face {
  font-family: "Roboto";
  src: url("./assets/font/roboto/Roboto-Regular.ttf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}
.swal2-header{

  padding: 0 !important;
}
.swal2-actions{
  margin: 2px !important;
  padding: 2px !important;
}

.confirmButtonText-class {
  color: #ffffff;
}
.cancelButtonText-class {
  color: #ffffff;
}
.title-class {
  font-size: 17px !important;
  font-weight: 500 !important;
}
.popup-class {
  border-radius: 20px !important;

  border-width: 2px !important;
  
}


.icon-class {
  height: 4em !important;
  width: 4em !important;
}

.content-class {
  color: #00a0c9 !important;
  font: 16px "Roboto" !important;
}
.confirmButton-class {
  border: none;
  background-color: #e31574;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font: bold 18px "Roboto";
  font-size: 18px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 10px;
  margin-right: 10px;
  outline: none;
}
.cancelButton-class {
  border-color: #e31574;
  border-style: solid;
  border: 10ch;
  background-color: gray;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 10px;
  margin-left: 10px;
  outline: none;
  margin-right: 20px;
  font: bold 18px "Roboto"
}
</style>
