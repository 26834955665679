<template>
  <header class="header-section">
    
    <nav class="header-bar">
      <img class="logo" src="../assets/img/logo-nombre.png" alt="Logo Tranzfer.me" srcset />
      
        <div class="select-lang">
          <!-- <div class="btn-redirection">
            <a type="button" href="https://develop.webapp.tranzfer.me/auth/register-customer" target="_blank" class="btn-register">{{$t("register")}}</a>
            <a type="button" href="https://develop.webapp.tranzfer.me/auth/login" target="_blank" class="btn-session">{{$t("session")}}</a>
          </div> -->
          <div class="select-model">

            <select class="select" v-model="$i18n.locale">
                <option value ="ESP">PER</option>
                <option value ="ENG">ENG</option>
                <option value ="POR">POR</option> 
            </select>    
          </div>
        </div>

    </nav>
  </header>
</template>

<script>
export default {
};
</script>

<style scoped>

.btn-redirection{
    width: 100%;
    display: flex;
    align-items: center;
}

.btn-register{
    border: 1px solid #79B3CE;
    border-radius: 15.04px;
    font-size: 17px;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    padding: 0.25rem;
    color: #2EA2C7;
    text-align: center;
    margin-right: 1rem;
    width: 111px;
    height: 30.09px;
}

.btn-session{
    border: 1px solid #79B3CE;
    border-radius: 15.04px;
    font-size: 17px;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
    padding: 0.25rem;
    color: #fff;
    background: #2DA1C6;
    text-align: center;
    width: 136px;
    height: 30.09px;
}


.select{
  color: #333333;
  background-color:#fff;
  border: 1px solid #2DA1C6;
  cursor:pointer;
  border-radius: 5px;
  width: 68px;
  appearance: none;
  padding-left: 27px;
}

.select-lang{
  cursor:pointer;
  display:flex;
  justify-content: space-between;
}

.select-model{
  cursor: pointer;
  display: flex;
}

.header-section {
  position: sticky; 
  top: 0;
  z-index: 1;
  background-color: white;
  box-shadow: 0px 3px 10px rgba(0,0,0,0.4);
}

.header-bar {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  height: 70px;
}

.logo {
  height: 30px;
}

.select-model:before{
    position:relative;
    z-index:100000; 
    left: 24px;
    top: 2px;
    content: url("../assets/img/idioma_tz.svg");
    width: 20px;
}

.select-lang:focus-visible{
  outline: none;
}

.select:focus-visible{
  outline: none;
}


/*--modificar flecha--*/

.select-model::after {
  content: '';
  position: relative;
  right: 13px;
  top: 10px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #2DA1C6;
  pointer-events: none;
}

 @media screen and (max-width: 600px){
      .btn-register{
        display: none;
      }
      .btn-session{
        width: 115px;
        font-size: 15px;
        padding: 0.30rem;
        margin-right: -0.5rem;
        margin-left: 1rem;
      }
      .select-lang{
        height: 34px;
      }

      .select{
        width: 41px;
        background: white;
        border: none;
        color: white;
      }
      option {
        color: #333333;
      }
      
  }


  @media screen and (max-width: 360px){
    .btn-session{
        width: 100px;
        font-size: 13px;
        padding: 0.40rem;
    }
  }

  @media screen and (max-width: 320px){
     .select{
      width: 24px;
      text-align: center;
      font-size: 9px;
    }
    .select-lang{
      width: 50%;
      height: 28px!important;
    }
    .btn-session{
      font-size: 10px;
      height: 28px;
      margin-left: 0.5rem;
    }

    .select-model:before {
      top: 3px;
      left: 10px;
    }

    .select-model:after {
      top: 12px;
    }
  }

</style>
