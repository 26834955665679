<template>
  <section class="info-section">
    <div class="info-img">
      <img src="../assets/img/info.jpg" width="300px" alt="" srcset="">
    </div>
    <div class="info-txt">
      <h1>{{ $t('title') }}</h1>
      <p>{{ $t('message') }}</p>
    </div>
  </section>
</template>

<script>
export default {
  name: "Info"
}
</script>

<style scoped>

.info-section {
  display: grid;
  grid-template-columns: 2fr 3fr;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  padding: 20px 0px;
}

.info-img {
  text-align: center;
}

.info-txt {
  padding: 0px 20px;
  text-align: center;
}

.info-txt h1 {
  color: #0C2A7A;
  font-size: 32px;
  padding-bottom: 26px;
}

.info-txt p {
  color: gray;
  font-size: 20px;
}

@media (max-width: 900px) {
  .info-section { grid-template-columns: 1fr; }
}

</style>