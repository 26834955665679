<template>
  <section id="calculator-background" class="langing-section">
    <div class="landing-container">
      <div class="calculadora">
        <img class="logo-z" src="../assets/img/logo.png" alt srcset />
        <p class="input-label">{{ $t("sending") }}</p>
        <div class="input-div-envias">
          <input
            v-on:keypress="checkInput($event)"
            v-on:keyup="changeEnvias()"
            v-model="inputEnvias"
            class="input-envias"
            type="texto"
            value="100.00"
            placeholder="100.00"
            maxlength="8"
          />
          <div class="dropdown">
            <button @click.prevent="envias()" class="dropbtn">
              <img
                :src="require(`../assets/img/${selectedEnvias.flag}`)"
                class="peru-img"
                width="20px"
              />
              {{ selectedEnvias.currency }}
              <img class="arrow" src="../assets/img/custom-select.png" />
            </button>
            <div v-if="showDropEnvias" class="dropdown-content">
              <a
                @click="selectEnvias(optionEnvias)"
                v-bind:key="index"
                v-for="(optionEnvias, index) in $t('dropOptions')"
              >
                <img
                  :src="require(`../assets/img/${optionEnvias.flag}`)"
                  class="peru-img"
                  width="20px"
                />
                {{ optionEnvias.currency }} &nbsp;
                <span class="span-drop">{{ optionEnvias.country }}</span>
              </a>
            </div>
          </div>
        </div>
        <div
          class="error-envias-container"
          v-bind:class="{ disable: disableOn }"
        >
          <p v-if="errorInputEnvias" class="error-input">
            {{ errorMessageEnvias }}
          </p>
        </div>
        <div
          class="error-maximo-container"
          v-bind:class="{ disable: disableOn }"
        >
          <p
            v-if="errorMaximoEnvia"
            class="error-maximo"
            id="activar-nuevo-maximo"
          >
            {{ messageMaximoEnvia }}
            <button
              v-if="errorMaximoEnvia"
              v-on:click="confirmNewMaximusEnvia"
              class="button-maximus"
            >
              {{ $t("ingresaAqui") }}
            </button>
          </p>
        </div>
        <div
          class="regresar-tarifa-container"
          v-bind:class="{ disable: disableOn }"
        >
          <p v-if="tarifaMinEnvia" class="message-min" id="activar-tarifa-min">
            {{ enviarMinEnvia }}
          </p>
          <button
            v-on:click="oldMaximusEnvia"
            v-if="tarifaMinEnvia"
            class="button-maximus"
          >
            {{ $t("regresar") }}
          </button>
        </div>

        <div class="comision-div">
          <p class="envio-label">{{ $t("shipping") }}:</p>
          <div v-if="metodoMaximoOff" class="dropdown metodo-envio">
            <button @click.prevent="metodoEnvio()" class="dropbtn">
              {{ selectedMetodoEnvio.metodo }}
              <img class="arrow" src="../assets/img/custom-select.png" />
            </button>
            <div v-if="showDropMetodoEnvio" class="dropdown-content">
              <a
                @click="selectMetodoEnvio(optionEnvio)"
                v-bind:key="index"
                v-for="(optionEnvio, index) in metodoEnvioOptions"
                >{{ optionEnvio.metodo }}</a
              >
            </div>
          </div>
          <div v-if="metodoMaximoOn" class="dropdown metodo-envio">
            <button @click.prevent="metodoEnvio()" class="dropbtn">
              {{ selectedMetodoEnvio.metodo2 }}
              <img class="arrow" src="../assets/img/custom-select.png" />
            </button>
            <div v-if="showDropMetodoEnvio" class="dropdown-content">
              <a
                @click="selectMetodoEnvio(optionEnvio)"
                v-bind:key="index"
                v-for="(optionEnvio, index) in metodoEnvioOptions"
                >{{ optionEnvio.metodo2 }}</a
              >
            </div>
          </div>
          <div class="tooltip">
            <img
              src="../assets/img/info.png"
              style="opacity: 0.3"
              width="20px"
              alt
              srcset
            />
            <span class="tooltiptext">
              {{
                selectedMetodoEnvio.metodo == $t("metodoEnvioOptions[0].metodo")
                  ? $t("metodoEnvioOptions[0].tooltip")
                  : $t("metodoEnvioOptions[1].tooltip")
              }}
            </span>
          </div>
        </div>
        <p v-if="errorTipoEnvio" class="error-tipoenvio-input">
          Debe seleccionar un tipo de envío
        </p>
        <div class="comision-calculo-div">
          <div class="resume-row">
            <p class="comision-calculo">{{ $t("commission") }}:</p>
            <p class="span-purple">
              {{ selectedEnvias.currency }} {{ this.comision }}
            </p>
          </div>
          <div class="resume-row">
            <p class="comision-calculo">{{ $t("taxes") }}:</p>
            <p class="span-purple">
              {{ selectedEnvias.currency }}
              {{ (comision * 0.18).toFixed(2) }}
            </p>
          </div>
          <div class="resume-row">
            <p class="comision-calculo">{{ $t("subtotal") }}:</p>
            <p class="span-purple">
              {{ selectedEnvias.currency }}
              {{ (comision * 1 + comision*0.18).toFixed(2) }}
            </p>
          </div>
          <div class="resume-row">
            <p class="comision-calculo">{{ $t("totalconvert") }}:</p>
            <p class="span-purple">
              {{ selectedEnvias.currency }}
              {{ (inputEnvias - (comision * 1 + comision*0.18)).toFixed(2) }}
            </p>
          </div>

          <div class="resume-row">
            <p class="comision-calculo">{{ $t("exchrate") }}:</p>
            <p class="span-purple">{{ exchangeRate }}</p>
            <div class="tooltip" v-show="tbd">
              <i
                class="fas fa-exclamation-circle"
                style="opacity: 0.9; color: red"
              ></i>
              <span class="tooltiptext">{{ tbdTooltip }} </span>
            </div>
          </div>
        </div>

        <p class="input-label">{{ $t("receives") }}</p>
        <div class="input-div-envias">
          <input
            v-on:keypress="checkInput($event)"
            v-on:keyup="changeRecibe()"
            v-model="inputRecibe"
            class="input-envias"
            type="text"
            placeholder="0.00"
          />
          <div class="dropdown">
            <button @click.prevent="recibe()" class="dropbtn">
              <img
                :src="require(`../assets/img/${selectedRecibe.flag}`)"
                class="peru-img"
                width="20px"
              />
              {{ selectedRecibe.currency }}
              <img class="arrow" src="../assets/img/custom-select.png" />
            </button>
            <div v-if="showDropRecibe" class="dropdown-content">
              <a
                @click="selectRecibe(optionRecibe)"
                v-bind:key="index"
                v-for="(optionRecibe, index) in $t('dropOptionsRecibe')"
              >
                <img
                  :src="require(`../assets/img/${optionRecibe.flag}`)"
                  class="peru-img"
                  width="20px"
                />
                {{ optionRecibe.currency }} &nbsp;
                <span class="span-drop">{{ optionRecibe.country }}</span>
              </a>
            </div>
          </div>
        </div>
        <div
          class="error-recibe-container"
          v-bind:class="{ disable: disableOn }"
        >
          <p v-if="errorInputRecibe" class="error-input">
            {{ errorMessageRecibe }}
          </p>
        </div>
        <div
          class="error-maximo-container"
          v-bind:class="{ disable: disableOn }"
        >
          <p
            v-if="errorMaximoRecibe"
            class="error-maximo"
            id="activar-nuevo-maximo"
          >
            {{ messageMaximoRecibe }}
            <button
              v-if="errorMaximoRecibe"
              v-on:click="confirmNewMaximusRecibe"
              class="button-maximus"
            >
              {{ $t("ingresaAqui") }}
            </button>
          </p>
        </div>
        <div
          class="regresar-tarifa-container"
          v-bind:class="{ disable: disableOn }"
        >
          <p
            v-if="tarifaMinRecibe"
            class="message-min-recibe"
            id="activar-tarifa-min-recibe"
          >
            {{ enviarMinRecibe }}
          </p>
          <button
            v-on:click="oldMaximusRecibe"
            v-if="tarifaMinRecibe"
            class="button-maximus"
          >
            {{ $t("regresar") }}
          </button>
        </div>

        <div class="terminos-div">
          <div class="card">
            <div class="checkbox-container">
              <label class="checkbox-label">
                <input
                  @change="checkTerminos()"
                  v-model="checked"
                  id="terminos-checkbox"
                  type="checkbox"
                />
                <span class="checkbox-custom rectangular"></span>
              </label>
            </div>
          </div>
          <a
            class="comision-calculo"
            onclick="opentyc();"
            style="cursor: pointer"
            >{{ $t("terms") }}</a
          >
        </div>
        <button
          v-if="metodoMaximoOff"
          v-bind:class="checked && allowed ? '' : 'disabled'"
          @click="realizarTransferencia()"
          class="button button-purple"
        >
          {{ $t("sendmoney") }}
        </button>
        <button
          v-if="metodoMaximoOn"
          v-bind:class="checked ? '' : 'disabled'"
          @click="checkTotalMaximos()"
          class="button button-purple"
        >
          {{ $t("sendmoney") }}
        </button>
        <div class="logo-container">
          <img class="logo" src="../assets/img/logo-nombre.png" alt srcset />
        </div>
      </div>
    </div>
  </section>
</template>



<script>
import axios from "axios";
import pulgar_arriba from "../assets/img/pulgar.png";
import image_banner_arg from "../assets/img/agrnetina final_Argentina popup.png";

export default {
  name: "Calculator",

  data() {
    return {
      metodoMaximoOn: false,
      metodoMaximoOff: true,
      disableOff: true,
      disableOn: false,
      langs: ["ESP", "ENG", "POR"],
      montosMaximosEnviaOn: false,
      montosMaximosEnviaOff: true,
      montosMaximosRecibeOn: false,
      montosMaximosRecibeOff: true,
      showDropEnvias: false,
      showDropRecibe: false,
      showDropMetodoEnvio: false,
      exchangeRate: "",
      inputEnvias: "",
      tarifaMinRecibe: false,
      tarifaMinEnvia: false,
      enviarMinRecibe: "",
      enviarMinEnvia: "",
      errorInputEnvias: false,
      errorMessageEnvias: "",
      errorMaximoRecibe: false,
      errorMaximoEnvia: false,
      messageMaximoEnvia: "",
      messageMaximoRecibe: "",
      errorInputRecibe: false,
      errorMessageRecibe: "",
      errorTipoEnvio: false,
      isFreeService: false,
      inputRecibe: "",
      comision: 0.0,
      validEvias: 0,
      checked: false,
      allowed: false,
      impuestoGlobal: 0.0,
      impuesto: 0.0,
      calculatedEnvio: "",
      calculatedImpuestos: "",
      calculatedSubtotal: "",
      calculatedTotal: "",
      tbd: false,
      tbdTooltip:
        "El servicio de cambio no responde, disculpe por favor, comuníquese con nosotros para continuar con el envío",
      montomax: "The maximium amount to send is",
      selectedEnvias: {
        country: this.$t("ee.uu"),
        flag: "USA.png",
        currency: "USD",
      },

      selectedRecibe: {
        country: "Peru",
        flag: "PER.png",
        currency: "PEN",
      },
      selectedMetodoEnvio: {
        metodo: "Seleccionar",
        metodo2: "seleccionar",
        costo: 0,
        costo2: 0,
        tooltip: "Seleccionar envío",
      },
      metodoEnvioOptions: [
        {
          metodo: "Normal (1.50%)",
          metodo2: "Normal (5.00%)",
          costo: 0.015,
          costo2: 0.05,
          tooltip: this.$t("max72"),
        },

        {
          metodo: "Premium (3.00%)",
          metodo2: "Premium (7.00%)",
          costo: 0.03,

          costo2: 0.07,
          tooltip: this.$t("max24"),
        },
      ],
      dropOptions: [
        {
          country: "Peru",
          flag: "PER.png",
          currency: "PEN",
        },
        {
          country: "Peru",
          flag: "PER.png",
          currency: "USD",
        },
        {
          country: "Estados Unidos",
          flag: "USA.png",
          currency: "USD",
        },
        {
          country: "Brasil",
          flag: "BRA.png",
          currency: "BRL",
        },
      ],
      dropOptionsRecibe: [
        {
          country: "Peru",
          flag: "PER.png",
          currency: "PEN",
        },
        {
          country: "Peru",
          flag: "PER.png",
          currency: "USD",
        },
        {
          country: "Estados Unidos",
          flag: "USA.png",
          currency: "USD",
        },
        {
          country: "Brasil",
          flag: "BRA.png",
          currency: "BRL",
        },
      ],
    };
  },
  methods: {
    checkTotalMaximos() {
      //ENVIAR DINERO DE USA A CUALQUIER PAIS
      if (
        this.selectedEnvias.flag == "USA.png" &&
        this.selectedEnvias.currency == "USD" &&
        this.metodoMaximoOn == true
      ) {
        if (this.inputEnvias <= 500) {
          this.verificarMontosMaximos();
        } else {
          this.realizarTransferencia();
        }
      }
      //ENVIAR DINERO DE BRASIL A PERU(PEN)
      if (
        this.selectedEnvias.flag == "BRA.png" &&
        this.selectedEnvias.currency == "BRL" &&
        this.selectedRecibe.currency == "PEN" &&
        this.selectedRecibe.flag == "PER.png"
      ) {
        if (this.inputEnvias <= 5000) {
          this.verificarMontosMaximos();
        } else {
          this.realizarTransferencia();
        }
      }
      //ENVIAR DINERO DE BRASIL A PERU(USD)
      if (
        (this.selectedEnvias.flag == "BRA.png" &&
          this.selectedEnvias.currency == "BRL" &&
          this.selectedRecibe.currency == "USD" &&
          this.selectedRecibe.flag == "PER.png") ||
        (this.selectedEnvias.flag == "BRA.png" &&
          this.selectedEnvias.currency == "BRL" &&
          this.selectedRecibe.currency == "USD" &&
          this.selectedRecibe.flag == "USA.png")
      ) {
        if (this.inputEnvias <= 5000) {
          this.verificarMontosMaximos();
        } else {
          this.realizarTransferencia();
        }
      }
      //Enviar dinero de Peru (PEN) a Brasil
      if (
        this.selectedEnvias.flag == "PER.png" &&
        this.selectedEnvias.currency == "PEN" &&
        this.selectedRecibe.flag == "BRA.png" &&
        this.selectedRecibe.currency == "BRL"
      ) {
        if (this.inputEnvias <= 5000) {
          this.verificarMontosMaximos();
        } else {
          this.realizarTransferencia();
        }
      }
      //Enviar dinero de Peru (USD) a Brasil
      if (
        this.selectedEnvias.flag == "PER.png" &&
        this.selectedEnvias.currency == "USD" &&
        this.selectedRecibe.flag == "BRA.png" &&
        this.selectedRecibe.currency == "BRL"
      ) {
        if (this.inputEnvias <= 3000) {
          this.verificarMontosMaximos();
        } else {
          this.realizarTransferencia();
        }
      }
      //Enviar dinero de Peru (PEN) a Estados Unidos
      if (
        this.selectedEnvias.flag == "PER.png" &&
        this.selectedEnvias.currency == "PEN" &&
        this.selectedRecibe.flag == "USA.png" &&
        this.selectedRecibe.currency == "USD"
      ) {
        if (this.inputRecibe <= 500) {
          this.verificarMontosMaximos();
        } else {
          this.realizarTransferencia();
        }
      }
      //Enviar dinero de Peru (USD) a Estados unidos
      if (
        this.selectedEnvias.flag == "PER.png" &&
        this.selectedEnvias.currency == "USD" &&
        this.selectedRecibe.flag == "USA.png" &&
        this.selectedRecibe.currency == "USD"
      ) {
        if (this.inputRecibe <= 500) {
          this.verificarMontosMaximos();
        } else {
          this.realizarTransferencia();
        }
      }
    },
    cargarBannerInformativo() {
      this.$swal({
        buttonsStyling: false,
        iconColor: "#E31574",
        imageUrl: image_banner_arg,
        imageAlt: "Pulgar arriba",
        confirmButtonText: "Accede aquí",
        customClass: {
          //   container: "container-class",
          popup: "popup-class",
          //    header: "...",
          title: "title-class",
          //    closeButton: "...",
          icon: "icon-class",
          //    image: "...",
          content: "content-class",
          //    input: "...",
          //    validationMessage: "...",
          //    actions: "...",
          confirmButton: "confirmButton-class",
          //    denyButton: "...",
          cancelButton: "cancelButton-class",
          //    loader: "...",
          //    footer: "....",
        },
        showCloseButton: true
      }).then((result) => {
        if (result.isConfirmed) {
          let link = `https://wa.me/51981194689?text=Deseo hacer un envío 🇦🇷`;
          window.open(link);
        }
      });
    },
    verificarMontosMaximos() {
      this.$swal({
        title: this.$t("menorTasa"),
        html: this.$t("envioNormal1.5") + "<br>" + this.$t("envioPremium3.0"),
        buttonsStyling: false,
        iconColor: "#E31574",
        //icon: "warning",
        imageUrl: pulgar_arriba,
        imageWidth: "70px",
        imageHeight: "70px",
        imageAlt: "Pulgar arriba",
        customClass: {
          //   container: "container-class",
          popup: "popup-class",
          //    header: "...",
          title: "title-class",
          //    closeButton: "...",
          icon: "icon-class",
          //    image: "...",
          content: "content-class",
          //    input: "...",
          //    validationMessage: "...",
          //    actions: "...",
          confirmButton: "confirmButton-class",
          //    denyButton: "...",
          cancelButton: "cancelButton-class",
          //    loader: "...",
          //    footer: "....",
        },
        showCloseButton: true,
        cancelButtonText: this.$t("cancelar"),
        confirmButtonText: this.$t("continuar"),
      }).then((result) => {
        if (result.isConfirmed) {
          this.oldMaximusGeneral();
        }
      });
    },

    confirmNewMaximusEnvia() {
      this.$swal({
        title: this.$t("montosMaxtitle"),
        html: this.$t("envioNormal5") + "<br>" + this.$t("envioPremium7"),
        icon: "warning",
        iconColor: "#E31574",
        buttonsStyling: false,
        reverseButtons: true,
        customClass: {
          //   container: "container-class",
          popup: "popup-class",
          //    header: "...",
          title: "title-class",
          //    closeButton: "...",
          icon: "icon-class",
          //    image: "...",
          content: "content-class",
          //    input: "...",
          //    validationMessage: "...",
          //    actions: "...",
          confirmButton: "confirmButton-class",
          //    denyButton: "...",
          cancelButton: "cancelButton-class",
          //    loader: "...",
          //    footer: "....",
        },
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: this.$t("cancelar"),
        confirmButtonText: this.$t("continuar"),
      }).then((result) => {
        if (result.isConfirmed) {
          this.newMaximusEnvias();
        }
      });
    },

    confirmNewMaximusRecibe() {
      this.$swal({
        title: this.$t("montosMaxtitle"),
        html: this.$t("envioNormal5") + "<br>" + this.$t("envioPremium7"),
        icon: "warning",
        iconColor: "#E31574",
        buttonsStyling: false,
        reverseButtons: true,
        customClass: {
          //   container: "container-class",
          popup: "popup-class",
          //    header: "...",
          title: "title-class",
          //    closeButton: "...",
          icon: "icon-class",
          //    image: "...",
          content: "content-class",
          //    input: "...",
          //    validationMessage: "...",
          //    actions: "...",
          confirmButton: "confirmButton-class",
          //    denyButton: "...",
          cancelButton: "cancelButton-class",
          //    loader: "...",
          //    footer: "....",
        },
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText: this.$t("cancelar"),
        confirmButtonText: this.$t("continuar"),
      }).then((result) => {
        if (result.isConfirmed) {
          this.newMaximusRecibe();
        }
      });
    },

    checkInput(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    envias() {
      this.showDropRecibe = false;
      this.showDropMetodoEnvio = false;

      this.showDropEnvias = !this.showDropEnvias;
    },
    metodoEnvio() {
      this.showDropEnvias = false;
      this.showDropRecibe = false;

      this.showDropMetodoEnvio = !this.showDropMetodoEnvio;
    },
    recibe() {
      this.showDropEnvias = false;
      this.showDropMetodoEnvio = false;

      this.showDropRecibe = !this.showDropRecibe;
    },
    close(e) {
      var dropDownEl = document.getElementsByClassName("dropdown");
      if (
        !dropDownEl[0].contains(e.target) &&
        !dropDownEl[1].contains(e.target) &&
        !dropDownEl[2].contains(e.target)
      ) {
        this.showDropEnvias = false;
        this.showDropRecibe = false;
        this.showDropMetodoEnvio = false;
      }
    },
    selectEnvias(envias) {
      if (envias.flag == this.selectedRecibe.flag) {
        let newRecibe = this.dropOptionsRecibe.filter(
          (opt) => opt.flag != envias.flag
        );
        this.selectedRecibe = newRecibe[0];
      }

      this.errorInputEnvias = false;
      this.selectedEnvias = envias;
      this.showDropEnvias = false;
      this.cambio(this.changeEnvias);
      this.checkTotal();
      this.checkTerminos();
    },
    selectRecibe(recibe) {
      if (recibe.flag == this.selectedEnvias.flag) {
        let newEnvias = this.dropOptionsRecibe.filter(
          (opt) => opt.flag != recibe.flag
        );
        this.selectedEnvias = newEnvias[0];
      }

      this.selectedRecibe = recibe;
      this.showDropRecibe = false;
      this.cambio(this.changeRecibe);
      this.checkTotal();
      this.checkTerminos();
    },
    selectMetodoEnvio(metodo) {
      this.selectedMetodoEnvio = metodo;
      this.changeEnvias();
      this.showDropMetodoEnvio = false;
      this.checkTotal();
      this.checkTerminos();
    },
    checkTerminos() {
      // console.log("Checked")
      // console.log(this.errorInputEnvias)
      // console.log(this.errorInputRecibe)
      // console.log(this.selectedMetodoEnvio.metodo)
      // console.log("Terminos ", this.checked);
      this.checkTotal();
      if (this.errorInputEnvias || this.errorInputRecibe) {
        // console.log("Not allowed");
        this.allowed = false;
      } else if (
        this.checked &&
        this.selectedMetodoEnvio.metodo == "Seleccionar"
      ) {
        // console.log("Not allowed 2");
        this.errorTipoEnvio = true;
        this.allowed = false;
      } else {
        this.allowed = true;
        this.errorTipoEnvio = false;
      }
    },
    checkTotal() {
      // let total = (
      //   this.inputEnvias -
      //   this.comision * (1 + this.impuesto)
      // ).toFixed(2);
      // console.log("total " + total);
      //ENVIAR DINERO DE BRASIL A PERU(PEN)
      if (
        this.selectedEnvias.flag == "BRA.png" &&
        this.selectedEnvias.currency == "BRL" &&
        this.selectedRecibe.currency == "PEN" &&
        this.selectedRecibe.flag == "PER.png"
      ) {
        if (this.inputEnvias > 9999999) {
          this.errorInputEnvias = true;
          this.errorMaximoEnvia = true;
          this.errorMaximoRecibe = false;
          this.messageMaximoEnvia = `${this.$t("enviarMax")}`;
          this.errorMessageEnvias = `${this.$t("montoMax")} ${
            this.selectedEnvias.currency
          } 9999999`;
          this.errorInputRecibe = false;
          this.errorMessageRecibe = "";
        } else if (
          (this.inputEnvias > 0 || this.checked) &&
          this.inputEnvias < 45
        ) {
          this.errorInputEnvias = true;
          this.errorMessageEnvias = `${this.$t("montoMin")} ${
            this.selectedEnvias.currency
          } 45`;
          this.errorInputRecibe = false;
          this.errorMessageRecibe = "";
          this.errorMaximoEnvia = false;
          this.messageMaximoEnvias = "";
        } else {
          this.errorInputEnvias = false;
          this.errorMessageEnvias = "";
          this.errorMaximoEnvia = false;
          this.messageMaximoEnvia = "";
        }
      }
      //
      //EMVIAR DINERO DE BRASIL A PERU(USD)
      if (
        this.selectedEnvias.flag == "BRA.png" &&
        this.selectedEnvias.currency == "BRL" &&
        this.selectedRecibe.currency == "USD" &&
        this.selectedRecibe.flag == "PER.png"
      ) {
        if (this.inputEnvias > 9999999) {
          this.errorInputEnvias = true;
          this.errorMaximoEnvia = true;
          this.errorMaximoRecibe = false;
          this.messageMaximoEnvia = `${this.$t("enviarMax")}`;
          this.errorMessageEnvias = `${this.$t("montoMax")} ${
            this.selectedEnvias.currency
          } 9999999`;
          this.errorInputRecibe = false;
          this.errorMessageRecibe = "";
        } else if (
          (this.inputEnvias > 0 || this.checked) &&
          this.inputEnvias < 45
        ) {
          this.errorInputEnvias = true;
          this.errorMessageEnvias = `${this.$t("montoMin")} ${
            this.selectedEnvias.currency
          } 45`;
          this.errorInputRecibe = false;
          this.errorMessageRecibe = "";
          this.errorMaximoEnvia = false;
          this.messageMaximoEnvias = "";
        } else {
          this.errorInputEnvias = false;
          this.errorMessageEnvias = "";
          this.errorMaximoEnvia = false;
          this.messageMaximoEnvia = "";
        }
      }
      //EMVIAR DINERO DE BRASIL A EEUU
      if (
        this.selectedEnvias.flag == "BRA.png" &&
        this.selectedEnvias.currency == "BRL" &&
        this.selectedRecibe.currency == "USD" &&
        this.selectedRecibe.flag == "USA.png"
      ) {
        if (this.inputRecibe > 9999999) {
          this.errorInputRecibe = true;
          this.errorMaximoRecibe = true;
          this.errorMessageRecibe = `${this.$t("montoMaxR")} ${
            this.selectedRecibe.currency
          } 9999999`;
          this.messageMaximoRecibe = `${this.$t("recibirMax")}`;
          this.errorInputEnvias = false;
          this.errorMessageEnvias = "";
          this.messageMaximoEnvias = "";
          this.errorMaximoEnvia = false;
        } else if (
          (this.inputEnvias > 0 || this.checked) &&
          this.inputEnvias < 45
        ) {
          this.errorInputEnvias = true;
          this.errorMessageEnvias = `${this.$t("montoMin")} ${
            this.selectedEnvias.currency
          } 45`;
          this.errorInputRecibe = false;
          this.errorMessageRecibe = "";
          this.errorMaximoRecibe = false;
          this.messageMaximoRecibe = "";
          this.errorMaximoEnvia = false;
          this.messageMaximoEnvia = "";
        } else {
          this.errorInputEnvias = false;
          this.errorMessageEnvias = "";
          this.errorInputRecibe = false;
          this.errorMessageRecibe = "";
          this.errorMaximoRecibe = false;
          this.messageMaximoRecibe = "";
          this.errorMaximoEnvia = false;
          this.messageMaximoEnvia = "";
        }
      }

      //
      // Enviar dinero de EEUU a todos los paises
      if (
        this.selectedEnvias.flag == "USA.png" &&
        this.selectedEnvias.currency == "USD"
      ) {
        if (this.inputEnvias > 9999999) {
          this.errorInputEnvias = true;
          this.errorMaximoEnvia = true;
          this.errorMaximoRecibe = false;
          this.messageMaximoRecibe = "";
          this.errorMessageEnvias = `${this.$t("montoMax")} ${
            this.selectedEnvias.currency
          } 9999999`;
          this.messageMaximoEnvia = `${this.$t("enviarMax")}`;
          this.errorInputRecibe = false;
          this.errorMessageRecibe = "";
        } else if (
          (this.inputEnvias > 0 || this.checked) &&
          this.inputEnvias < 10
        ) {
          this.errorInputEnvias = true;
          this.errorMessageEnvias = true;
          this.errorMessageEnvias = `${this.$t("montoMin")} ${
            this.selectedEnvias.currency
          } 10`;
          this.errorInputRecibe = false;
          this.errorMessageRecibe = "";
          this.errorMaximoRecibe = false;
          this.messageMaximoRecibe = "";
          this.errorMaximoEnvia = false;
          this.messageMaximoEnvia = "";
        } else {
          this.errorInputEnvias = false;
          this.errorMessageEnvias = "";
          this.errorMaximoRecibe = false;
          this.messageMaximoRecibe = "";
          this.errorMaximoEnvia = false;
          this.messageMaximoEnvia = "";
        }
      }
      //Enviar dinero de Peru (PEN) a Brasil
      if (
        this.selectedEnvias.flag == "PER.png" &&
        this.selectedEnvias.currency == "PEN" &&
        this.selectedRecibe.flag == "BRA.png" &&
        this.selectedRecibe.currency == "BRL"
      ) {
        if (this.inputEnvias > 9999999) {
          this.errorInputEnvias = true;
          this.errorMaximoEnvia = true;
          this.errorMaximoRecibe = false;
          this.errorMessageEnvias = true;
          this.errorMessageEnvias = `${this.$t("montoMax")} ${
            this.selectedEnvias.currency
          } 9999999`;
          this.messageMaximoEnvia = `${this.$t("enviarMax")}`;
          this.errorInputRecibe = false;
          this.errorMessageRecibe = "";
        } else if (
          (this.inputEnvias > 0 || this.checked) &&
          this.inputEnvias < 35
        ) {
          this.errorInputEnvias = true;
          this.errorMessageEnvias = `${this.$t("montoMin")} ${
            this.selectedEnvias.currency
          } 35`;
          this.errorInputRecibe = false;
          this.errorMessageRecibe = "";
          this.errorMaximoRecibe = false;
          this.messageMaximoRecibe = "";
          this.errorMaximoEnvia = false;
          this.messageMaximoEnvia = "";
        } else {
          this.errorInputEnvias = false;
          this.errorMessageEnvias = "";
          this.errorMaximoRecibe = false;
          this.messageMaximoRecibe = "";
          this.errorMaximoEnvia = false;
          this.messageMaximoEnvia = "";
        }
      }
      //
      //Enviar dinero de Peru (USD) a Brasil
      if (
        this.selectedEnvias.flag == "PER.png" &&
        this.selectedEnvias.currency == "USD" &&
        this.selectedRecibe.flag == "BRA.png" &&
        this.selectedRecibe.currency == "BRL"
      ) {
        if (this.inputEnvias > 9999999) {
          this.errorInputEnvias = true;
          this.errorMaximoEnvia = true;
          this.errorMessageEnvias = `${this.$t("montoMax")} ${
            this.selectedEnvias.currency
          } 9999999`;
          this.messageMaximoEnvia = `${this.$t("enviarMax")}`;
          this.errorInputRecibe = false;
          this.errorMessageRecibe = "";
        } else if (
          (this.inputEnvias > 0 || this.checked) &&
          this.inputEnvias < 10
        ) {
          this.errorInputEnvias = true;
          this.errorMessageEnvias = true;
          this.errorMessageEnvias = `${this.$t("montoMin")} ${
            this.selectedEnvias.currency
          } 10`;
          this.errorInputRecibe = false;
          this.errorMessageRecibe = "";
          this.errorMaximoRecibe = false;
          this.messageMaximoRecibe = "";
          this.errorMaximoEnvia = false;
          this.messageMaximoEnvia = "";
        } else {
          this.errorInputEnvias = false;
          this.errorMessageEnvias = "";
          this.errorMaximoRecibe = false;
          this.messageMaximoRecibe = "";
          this.errorMaximoEnvia = false;
          this.messageMaximoEnvia = "";
        }
      }
      //
      //Enviar dinero de Peru (PEN) a Estados Unidos
      if (
        this.selectedEnvias.flag == "PER.png" &&
        this.selectedEnvias.currency == "PEN" &&
        this.selectedRecibe.flag == "USA.png" &&
        this.selectedRecibe.currency == "USD"
      ) {
        if (this.inputRecibe > 9999999) {
          this.errorInputRecibe = true;
          this.errorMaximoRecibe = true;
          this.errorMessageRecibe = `${this.$t("montoMaxR")} ${
            this.selectedRecibe.currency
          } 9999999`;
          this.messageMaximoRecibe = `${this.$t("recibirMax")}`;
          this.errorInputEnvias = false;
          this.errorMessageEnvias = "";
          this.messageMaximoEnvias = "";
          this.errorMaximoEnvia = false;
        } else if (
          (this.inputEnvias > 0 || this.checked) &&
          this.inputEnvias < 35
        ) {
          this.errorInputEnvias = true;
          this.errorMessageEnvias = `${this.$t("montoMin")} ${
            this.selectedEnvias.currency
          } 35`;
          this.errorInputRecibe = false;
          this.errorMessageRecibe = "";
          this.errorMaximoRecibe = false;
          this.messageMaximoRecibe = "";
          this.errorMaximoEnvia = false;
          this.messageMaximoEnvia = "";
        } else {
          this.errorInputEnvias = false;
          this.errorMessageEnvias = "";
          this.errorInputRecibe = false;
          this.errorMessageRecibe = "";
          this.errorMaximoRecibe = false;
          this.messageMaximoRecibe = "";
          this.errorMaximoEnvia = false;
          this.messageMaximoEnvia = "";
        }
      }
      //
      //Enviar dinero de Peru (USD) a Estados unidos
      if (
        this.selectedEnvias.flag == "PER.png" &&
        this.selectedEnvias.currency == "USD" &&
        this.selectedRecibe.flag == "USA.png" &&
        this.selectedRecibe.currency == "USD"
      ) {
        if (this.inputRecibe > 9999999) {
          this.errorInputRecibe = true;
          this.errorMaximoRecibe = true;
          this.errorMaximoEnvia = false;
          this.errorMessageRecibe = `${this.$t("montoMaxR")} ${
            this.selectedRecibe.currency
          } 9999999`;
          this.messageMaximoRecibe = `${this.$t("recibirMax")}`;
          this.errorInputEnvias = false;
          this.errorMessageEnvias = "";
        } else if (
          (this.inputEnvias > 0 || this.checked) &&
          this.inputEnvias < 10
        ) {
          this.errorInputEnvias = true;
          this.errorMessageEnvias = `${this.$t("montoMin")} ${
            this.selectedEnvias.currency
          } 10`;
          this.errorInputRecibe = false;
          this.errorMessageRecibe = "";
          this.errorMaximoRecibe = false;
          this.messageMaximoRecibe = "";
          this.errorMaximoEnvia = false;
          this.messageMaximoEnvia = "";
        } else {
          this.errorInputEnvias = false;
          this.errorMessageEnvias = "";
          this.errorInputRecibe = false;
          this.errorMessageRecibe = "";
          this.errorMaximoRecibe = false;
          this.messageMaximoRecibe = "";
          this.errorMaximoEnvia = false;
          this.messageMaximoEnvia = "";
        }
      }
    },
    oldMaximusGeneral() {
      this.metodoMaximoOn = false;
      this.metodoMaximoOff = true;
      this.montosMaximosRecibeOn = false;
      this.montosMaximosRecibeOff = true;
      this.errorInputEnvias = true;
      this.disableOn = false;
      this.tarifaMinRecibe = false;
      this.errorMaximoEnvia = true;
      this.metodoMaximoOn = false;
      this.metodoMaximoOff = true;
      this.montosMaximosEnviaOn = false;
      this.montosMaximosEnviaOff = true;
      this.errorInputEnvias = true;
      this.disableOn = false;
      this.tarifaMinEnvia = false;
      this.errorMaximoEnvia = true;
      this.changeEnvias();
    },
    newMaximusEnvias() {
      this.metodoMaximoOn = true; //el metodo maximoOn para enviar se pone en verdadero
      this.metodoMaximoOff = false; //el metodo maximo Off para enviar se pone en false
      this.montosMaximosEnviaOn = true;
      this.montosMaximosEnviaOff = false;
      this.errorInputEnvias = false;
      this.disableOn = true;
      this.tarifaMinEnvia = true;
      this.enviarMinEnvia = `${this.$t("enviarMinEnvia")}`;
      this.errorMaximoEnvia = false;
      this.changeEnvias();
    },
    newMaximusRecibe() {
      this.metodoMaximoOn = true;
      this.metodoMaximoOff = false;
      this.montosMaximosRecibeOn = true;
      this.montosMaximosRecibeOff = false;
      this.errorInputEnvias = false;
      this.errorInputRecibe = false;
      this.disableOn = true;
      this.tarifaMinRecibe = true;
      this.enviarMinRecibe = `${this.$t("enviarMinRecibe")}`;
      this.errorMaximoEnvia = false;
      this.changeEnvias();
    },
    oldMaximusEnvia() {
      this.metodoMaximoOn = false;
      this.metodoMaximoOff = true;
      this.montosMaximosEnviaOn = false;
      this.montosMaximosEnviaOff = true;
      this.errorInputEnvias = true;
      this.disableOn = false;
      this.tarifaMinEnvia = false;
      this.errorMaximoEnvia = true;
      this.changeEnvias();
    },
    oldMaximusRecibe() {
      this.metodoMaximoOn = false;
      this.metodoMaximoOff = true;
      this.montosMaximosRecibeOn = false;
      this.montosMaximosRecibeOff = true;
      this.errorInputEnvias = true;
      this.disableOn = false;
      this.tarifaMinRecibe = false;
      this.errorMaximoEnvia = true;
      this.changeEnvias();
    },
    checkComisionMinima() {
      if (this.inputEnvias > 0 || this.inputRecibe > 0) {
        if (this.selectedEnvias.currency == "USD") {
          this.comision = this.comision < 1.0 ? 1.0 : this.comision;
        }
        if (this.selectedEnvias.currency == "PEN") {
          this.comision = this.comision < 3.6 ? 3.6 : this.comision;
        }
        if (this.selectedEnvias.currency == "BRL") {
          this.comision = this.comision < 5.5 ? 5.5 : this.comision;
        }
      }
      this.comision = this.comision.toFixed(2);
    },
    changeEnvias() {
      console.log("Change envias");

      if (
        (this.montosMaximosEnviaOn == true) |
        (this.montosMaximosRecibeOn == true)
      ) {
        if (!this.isFreeService) {
          this.comision = this.inputEnvias * this.selectedMetodoEnvio.costo2;
          this.checkComisionMinima();
      //   this.checkImpuesto();
        }
      } else {
        if (!this.isFreeService) {
          this.comision = this.inputEnvias * this.selectedMetodoEnvio.costo;
          this.checkComisionMinima();
     //    this.checkImpuesto();
        }
      }
      let totalAConvertir = (
        this.inputEnvias - (this.comision * 1 + this.comision*0.18)
      ).toFixed(2);

      let calculation = totalAConvertir * this.exchangeRate;
      this.inputRecibe = calculation.toFixed(2);
      if (this.inputRecibe < 0) {
        this.inputRecibe = 0.0;
      }
      this.checkTotal();
      this.checkTerminos();
    },
    changeRecibe() {
      //Condicional para nuevos maximos
      if (
        (this.montosMaximosEnviaOn == true) |
        (this.montosMaximosRecibeOn == true)
      ) {
        if (!this.isFreeService) {
      //    this.checkImpuesto();

          let totalAconvertir = this.inputRecibe / this.exchangeRate;

          let costo2 = this.selectedMetodoEnvio.costo2;

          let calculation = totalAconvertir / (1 - (1.18*costo2));

          this.comision = calculation * this.selectedMetodoEnvio.costo2;

          this.impuesto = this.comision*0.18;
          

          let comisiontemp = calculation * this.selectedMetodoEnvio.costo2;

          this.checkComisionMinima();

          if (comisiontemp != this.comision) {
            console.log(
              "comision cambio " + comisiontemp + " - " + this.comision
            );
            console.log();
            calculation = totalAconvertir + this.comision*1 + this.impuesto*1;
          }

          this.inputEnvias = calculation.toFixed(2);
        } else {
          this.inputEnvias = (this.inputRecibe / this.exchangeRate).toFixed(2);
        }

        this.checkTotal();
        this.checkTerminos();
      } else {
        //Sino estan activas las banderas, se encuentra en modo "montos minimos"
        if (!this.isFreeService) {
      //    this.checkImpuesto();

          let totalAconvertir = this.inputRecibe / this.exchangeRate;

          let costo = this.selectedMetodoEnvio.costo;

          

          let calculation = totalAconvertir / (1 - (costo*1.18));

          this.comision = calculation * this.selectedMetodoEnvio.costo;

          this.impuesto = this.comision*0.18;

          let comisiontemp = calculation * this.selectedMetodoEnvio.costo;

          this.checkComisionMinima();

          if (comisiontemp != this.comision) {
            console.log(
              "comision cambio " + comisiontemp + " - " + this.comision
            );
            console.log();
            calculation = totalAconvertir + this.comision*1 + this.impuesto*1;
          }

          this.inputEnvias = calculation.toFixed(2);
        } else {
          this.inputEnvias = (this.inputRecibe / this.exchangeRate).toFixed(2);
        }

        this.checkTotal();
        this.checkTerminos();
      }
    },
    cambio(callback) {
      let from = this.selectedEnvias.currency;
      let to = this.selectedRecibe.currency;
      axios
        .get(
          "https://api.tranzfer.me/exchange/converter",
          {
            params: {
              from: from,
              to: to,
              quantity: 1,
            },
            headers: {
              "x-api-key": "kK6cuUxVU87oqLWZgjTQL8bQQkkKfDWW6F6vSVyy",
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            let tempExchangeRate = res.data.body;
            this.exchangeRate = tempExchangeRate.rate_to;
            this.tdb = false;
            callback();
          } else {
            this.tbd = true;
          }
        })
        .catch((err) => {
          console.log("API de cambio caido " + err);
          this.tbd = true;
        });

    },
    realizarTransferencia() {
      // let line1 = `${this.$t("sending")}: ${this.selectedEnvias.currency} ${
      //   this.inputEnvias
      // } (${this.selectedEnvias.country})`;
      // let line2 = `${this.$t("exchrate")}: ${
      //   this.selectedRecibe.currency
      // } 1 = ${this.exchangeRate}`;
      // let line3 = `${this.$t("receives")}: ${this.selectedRecibe.currency} ${
      //   this.inputRecibe
      // } (${this.selectedRecibe.country})`;
      // let line4 = "";
      // if (
      //   (this.montosMaximosEnviaOn == true) |
      //   (this.montosMaximosRecibeOn == true)
      // ) {
      //   line4 = `${this.$t("shipping")}: ${this.selectedMetodoEnvio.metodo2}`;
      // } else {
      //   line4 = `${this.$t("shipping")}: ${this.selectedMetodoEnvio.metodo}`;
      // }
      // let line5 = `${this.$t("commission")}: ${this.selectedEnvias.currency} ${(
      //   this.comision * 1
      // ).toFixed(2)}`;
      // let line6 = `${this.$t("taxes")}: ${this.selectedEnvias.currency} ${(
      //   this.comision * 0.18
      // ).toFixed(2)}`;
      // let line7 = `${this.$t("subtotal")}: ${this.selectedEnvias.currency} ${(
      //   this.comision *1
      //    + this.comision*0.18
      // ).toFixed(2)}`;
      // let line8 = `${this.$t("totalconvert")}: ${
      //   this.selectedEnvias.currency
      // } ${(this.inputEnvias * 1 - (this.comision * 1 + this.comision*0.18)).toFixed(
      //   2
      // )}`;

      // let eLine1 = encodeURIComponent(line1);
      // let eLine2 = encodeURIComponent(line2);
      // let eLine3 = encodeURIComponent(line3);
      // let eLine4 = encodeURIComponent(line4);
      // let eLine5 = encodeURIComponent(line5);
      // let eLine6 = encodeURIComponent(line6);
      // let eLine7 = encodeURIComponent(line7);
      // let eLine8 = encodeURIComponent(line8);

      // let link = `https://wa.me/51981194689?text=${eLine1}%0a${eLine2}%0a${eLine3}%0a${eLine4}%0a${eLine5}%0a${eLine6}%0a${eLine7}%0a${eLine8}%0a`;
      let link = `https://www.webapp.tranzfer.me/auth/login`;
      window.open(link, '_self');

      // location.href = "mailto:"+ "tranzfers@tranzfer.me" +'?subject='+ "Requerimiento de envío de dinero Tranzfer.me" +'&body='+ `${eLine1}%0a${eLine2}%0a${eLine3}%0a${eLine4}%0a${eLine5}%0a${eLine6}%0a${eLine7}%0a${eLine8}%0a`;

    },
  },
  created() {
    window.addEventListener("click", this.close);
  },
  mounted() {
    this.cambio(this.changeEnvias);
    this.selectMetodoEnvio(this.metodoEnvioOptions[0]);
    this.inputEnvias = 100.00;
    this.changeEnvias();
  },
  beforeDestroy() {
    window.removeEventListener("click", this.close);
  },
  beforeMount(){
    // this.cargarBannerInformativo();
  }
};
</script>

<style scoped>
.langing-section {
  background-color: black;
  position: relative;
  background-image: url(../assets/img/fondo.jpg);
  background-size: cover;
  height: 700px;
}

.landing-container {
  height: 100%;
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.calculadora {
  margin: 0 auto;
  width: 400px;
  height: 600px;
  background: white;
  border-radius: 20px;
  padding: 25px 40px;
  box-shadow: 5px 10px 25px 2px rgba(0, 0, 0, 0.4);
}

.logo-z {
  width: 25%;
  margin-bottom: 10px;
}

.logo {
  height: 30px;
}

.input-label {
  text-align: left;
  margin-bottom: 0;
  color: #00b7e5;
}

.input-div-envias {
  border: 2px solid #00b7e5;
  border-radius: 40px;
  height: 40px;
  font-size: 14px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  overflow: hidden;
}

.input-div-envias.error {
  border: 2px solid red;
  box-shadow: 0 0 5px rgb(202, 109, 112);
}

.input-envias {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 0px 18px;
  font-size: 18px;
  color: #888;
  min-width: 0;
}

.error-input {
  font: bold 20px "Roboto";
  color: red;
  text-align: left;
  padding-left: 20px;
  margin-top: 0px;
  font-size: 12px;
}

.error-tipoenvio-input {
  color: red;
  text-align: left;
  padding-left: 20px;
  margin-top: 0px;
  font-size: 12px;
  text-align: center;
}

.peru-img {
  margin-right: 10px;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 170px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  text-align: left;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* Dropdown */

.dropbtn {
  color: gray;
  padding: 10px 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  background: none;
}
.arrow {
  margin-left: 7px;
}

.dropdown {
  display: inline-block;
}

.dropdown.metodo-envio {
  border: 2px solid #eaeaea;
  border-radius: 11px;
  padding: 5px 8px;
  margin-right: 5px;
}

.dropdown.metodo-envio > .dropdown-content {
  margin-left: -5px;
  margin-top: 8px;
  min-width: 140px;
}

.dropdown.metodo-envio > .dropdown-content > a {
  padding: 11px 18px;
}

.dropdown.metodo-envio > .dropbtn {
  font-size: 14px;
  padding: 0 0 0 5px;
}

.dropdown-content {
  position: absolute;
  background-color: #ffffff;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-left: -86px;
}

.dropdown-content a {
  display: flex;
  align-items: center;
  color: black;
  padding: 14px 19px;
  text-decoration: none;
}

.dropdown a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}

.span-drop {
  color: gray;
  margin-left: 5px;
}

/* Dropdown */

.cambio {
  padding: 19px 13px 0px 0px;
  color: gray;
  font-size: 14px;
  text-align: right;
}

.span-cambio {
  color: #e70081;
}

.comision-div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 10px 0px 0px;
  color: gray;
  font-size: 14px;
  text-align: left;
}

.resume-row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.envio-label {
  color: #00b7e5;
  padding-right: 5px;
}
x .comision-div .comision {
  padding: 2px 0;
}

.span-blue {
  color: #00b7e5;
  font-weight: 800;
}

.span-purple {
  color: #e70081;
  font-weight: 800;
  min-width: 80px;
}

.comision-calculo-div {
  padding: 20px 13px 10px 0;
  color: gray;
  font-size: 14px;
  text-align: right;
}

.comision-calculo-div .comision-calculo {
  padding: 2px 0;
  margin-right: 2px;
}

.terminos-div {
  padding: 5px 0 0 0;
  color: #00b7e5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  padding-top: 10px;
  border: none;
  outline: none;
  color: white;
  padding: 12px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 15px 2px;
  cursor: pointer;
  border-radius: 40px;
  width: 80%;
}

.button.disabled {
  pointer-events: none;
  background-color: #e2e2e2;
}

.comision-calculo {
  font-size: 14px;
  font-weight: 800;
}

.button-purple {
  background-color: #e70081;
}

.card {
  margin-right: 5px;
}

.card .checkbox-container {
  width: 50%;
  box-sizing: border-box;
  text-align: center;
}
.card .circular-container {
  background-color: #0067ff;
}

.input-title {
  clear: both;
  padding: 22px 0px 0px 0px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 300;
}

/* Styling Checkbox Starts */
.checkbox-label {
  display: block;
  position: relative;
  margin: auto;
  cursor: pointer;
  font-size: 22px;
  line-height: 24px;
  height: 24px;
  width: 24px;
  clear: both;
}

.checkbox-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-label .checkbox-custom {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border-radius: 5px;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  border: 2px solid #00b7e5;
}

.checkbox-label input:checked ~ .checkbox-custom {
  background-color: #00b7e5;
  border-radius: 5px;
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  opacity: 1;
  border: 2px solid #00b7e5;
}

.checkbox-label .checkbox-custom::after {
  position: absolute;
  content: "";
  left: 12px;
  top: 12px;
  height: 0px;
  width: 0px;
  border-radius: 5px;
  border: solid #009bff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(0deg) scale(0);
  -ms-transform: rotate(0deg) scale(0);
  transform: rotate(0deg) scale(0);
  opacity: 1;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}

.checkbox-label input:checked ~ .checkbox-custom::after {
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity: 1;
  left: 6px;
  top: 1px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
}

@media (max-width: 600px) {
  .calculadora {
    padding: 20px 20px;
    height: 630px;
  }
  .comision-div {
    padding: 10px 0px 0px 0px;
  }

  .tooltip .tooltiptext {
    margin-left: -125px;
  }
  .comision-div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 0px 0px 0px;
    color: gray;
    font-size: 14px;
    text-align: left;
  }

  .regresar-tarifa-container {
    display: inline !important;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-right: 20px;
    padding-left: 15px;
  }
  .button-maximus {
    margin-bottom: 0px;
    margin-left: 12px !important;
  }

  .message-min {
    padding-left: 0px !important;
    margin-top: 0px !important;
    color: gray;

    font: bold 12px "Roboto";
  }
  .message-min-recibe {
    margin-top: 0px !important;
    padding-left: 0px !important;
  }
}
.message-min {
  padding-left: 25px;
  margin-top: 12px;
  color: gray;

  font: bold 12px "Roboto";
}
.message-min-recibe {
  padding-left: 20px;
  margin-top: 12px;
  color: gray;

  font: bold 12px "Roboto";
}
.error-maximo {
  color: gray;
  font: bold 12px "Roboto";
}
.disable {
  display: none;
}
.regresar-tarifa-container {
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
}
.button-maximus {
  font: bold 12px "Roboto";
  margin-top: 6px;
  margin-left: 6px;
  text-decoration: none;
  border-color: #e31474;
  border-radius: 10px;
  padding: 4px 8px 4px 8px;
  background-color: #ffffff;
  color: #e70081;
  outline: none;
  cursor: pointer;
}
.error-envias-container {
  margin-top: 5px;
  margin-bottom: 5px;
}
.error-maximo-container {
  margin-top: 0px;
}
.logo-container {
  padding-bottom: 10px;
}
.error-recibe-container {
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
