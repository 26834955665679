import Vue from 'vue'
import App from './App.vue'
import money from 'v-money'
import i18n from './i18n'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'


Vue.use(VueSweetalert2)
Vue.use(money, {precision: 4})
Vue.config.productionTip = false

new Vue({
  i18n,
  render: h => h(App)
}).$mount('#app')