<template>
  <section class="aliados-section">
    
<!--     <a href="https://www.f10.global/spanish-page" target="_blank">
      <img src="../assets/img/f10.png" class="size" alt />
    </a> -->

    <a href="https://aiesec.pe/" target="_blank">
      <img src="../assets/img/aiesec.png" alt />
    </a>

    <a
      href="https://utecventures.medium.com/la-nueva-generaci%C3%B3n-de-startups-2020-45aeb4da67"
      target="_blank"
    >
      <img src="../assets/img/Ventures.png" alt />
    </a>

    <a href="https://www.instagram.com/workyandtravel/?hl=es" target="_blank">
      <img
        src="../assets/img/work-travel.png"
        width="300px"
        alt="word_travel"
      />
    </a>

    <a href="https://www.vozanimalperu.com/" target="_blank">
      <img src="../assets/img/Logo_Voz.png" width="300px" alt="logo_voz" />
    </a>

    <a href="https://www.midpointfx.com/" target="_blank">
      <img src="../assets/img/midpointfx.png" alt />
    </a>
  </section>
</template>

<script>
export default {
  name: "Allies",
};
</script>

<style scoped>
.aliados-section {
  text-align: center;

  justify-content: space-around;

  align-items: center;
  padding-bottom: 40px;
  padding-top: 40px;
  margin: 0 auto;
}

.aliados-section .size{
  width: 300px;
}

img {
  padding: 15px 60px 40px 60px;

  align-items: center;
}
</style>